<template>
  <div class="home">
    <Rive v-if="loggedIn" src="power-button.riv" />
    <h3 v-if="!loggedIn && !loading" style="color: white;">Please Login</h3>
  </div>
</template>

<script>
import Rive from '@/components/Rive.vue';

// @ is an alias to /src
export default {
  name: 'HomeView',
  components: {
    Rive,
  },
  data() {
    return {
      loading: true,
      loggedIn: false,
    };
  },
  mounted() {
    this.axios.get('auth/user')
      .then(() => {
        this.loading = false;
        this.loggedIn = true;
      })
      .catch(() => {
        this.loading = false;
        this.loggedIn = false;
        const popup = window.open('https://dash.rufeis.de/auth/login?redirect=close', 'popup1', 'width=600,height=400,status=yes,scrollbars=yes,resizable=yes');
        popup.focus();
      });
  },
};
</script>

<style scoped>

.home {
  background-color: #000F17;
  width: 100vw;
  max-width: 100%;
  flex-grow: 2;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>
