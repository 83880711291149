<template>
  <div>
    <!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events -->
    <canvas ref="canvas" class="rive" width="400" height="400" @click="play"></canvas>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Rive } from '@rive-app/canvas';

let animation;

export default defineComponent({
  name: 'RiveComponent',
  props: {
    src: String,
  },
  data() {
    return {
      animation: undefined,
    };
  },
  mounted() {
    animation = new Rive({
      canvas: this.$refs.canvas,
      src: this.$props.src,
      autoplay: false,
      onLoop: () => {
        animation.stop();
      },
    });
  },
  methods: {
    play() {
      this.axios.post('home/wakeup')
        .then(() => {
          animation.play('Animation 1', false);
        });
    },
  },
});
</script>

<style scoped>

.rive {
  cursor: pointer;
}

</style>
